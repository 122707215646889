import { RadioInputComponent } from './radio.input.component'

export class RadioTabsInputComponent extends RadioInputComponent {
  reset() {
    super.reset()

    // this.element is our .js-tab-trigger box
    this.element.classList.remove('active')
  }
}

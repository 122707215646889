export class Component {
  constructor(context, element, parent) {
    this.context = context
    this.element = element
    this.parent = parent

    element.configuratorComponent = this

    this.init()
  }

  init() {}

  reset() {}

  getValue() {}

  getParentOfType(classType) {
    let currentItem = this.parent

    do {
      if (currentItem instanceof classType) {
        return currentItem
      }

      currentItem = currentItem.parent
    } while (currentItem)

    return null
  }
}

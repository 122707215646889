import { Component } from '../component'
import { SectionComponent } from '../section/section.component'

export class LayerContentComponent extends Component {
  init() {
    this.backButtonEl = this.element.querySelector('.to-back')
    this.nextButtonEl = this.element.querySelector('.to-next_wrap')
    this.summaryEl = this.element.querySelector('.summary')

    this.initSections()
  }

  initSections() {
    this.sections = []

    const sectionEls = this.element.querySelectorAll('.configurator-section')

    for (const sectionEl of sectionEls) {
      const sectionElement = new SectionComponent(this.context, sectionEl, this)
      this.sections.push(sectionElement)
    }
  }

  activate() {
    this.getLayerTab().activate()
  }

  getLayerTab() {
    return this.parent
  }

  getNextButtonEl() {
    return this.nextButtonEl
  }

  reset() {
    this.element.classList.remove('active', 'current', 'finished')
    this.backButtonEl?.setAttribute('href', '#')

    if (this.summaryEl) {
      this.summaryEl.innerHTML = ''
    }

    for (const section of this.sections) {
      section.hide()
      section.reset()
    }
  }
}

import { ConfiguratorEventEmitter } from './configurator-event-emitter'

export class Context {
  constructor() {
    this.event = new ConfiguratorEventEmitter()
  }

  dispatchEvent(component, eventName, detail = {}) {
    const event = new CustomEvent(eventName, {
      detail: {
        ...detail,
        component,
      }
    });

    this.event.dispatchEvent(event)
  }
}

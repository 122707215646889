import { LayerCollection } from './layer-collection'
import { LayerTabComponent } from './component/layer/layer-tab.component'
import { Context } from './context'

export class ConfiguratorState {
  init() {
    this.context = new Context()
    this.event = this.context.event
    this.layerCollection = new LayerCollection()

    const layerContainerEl = document.querySelector('.configurator__layers')
    const layerEls = layerContainerEl.querySelectorAll('.configurator__layer')
    const configuratorContentEls = document.querySelectorAll('.configurator__content')

    const contentElementsById = Array.from(configuratorContentEls)
      .reduce(
        (itemsById, contentEl) => {
          itemsById[contentEl.dataset.id] = contentEl
          return itemsById
        },
        {}
      )

    for (const layer of layerEls) {
      const id = layer.dataset.id
      const contentEl = contentElementsById[id]
      const layerComponent = new LayerTabComponent(this.context, layer, contentEl, this.layerCollection)
      this.layerCollection.addLayer(layerComponent)
    }
  }
}

export class LayerCollection {
  constructor() {
    this.layers = []
  }

  addLayer(input) {
    this.layers.push(input)
  }

  reset() {
    for (const layer of this.layers) {
      layer.reset()
    }
  }
}

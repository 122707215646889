import { Component } from '../component'
import { LayerContentComponent } from './layer-content.component'

export class LayerTabComponent extends Component {
  constructor(context, element, contentEl, layerTree) {
    super(context, element, null)

    this.layerTree = layerTree
    this.content = new LayerContentComponent(context, contentEl, this)
    this.iconEl = element.querySelector('.layer-icon')

    this.iconNormal = this.iconEl.dataset.normal
    this.iconActive = this.iconEl.dataset.active
  }

  activate() {
    this.content.element.classList.remove('finished')
    this.content.element.classList.add('active', 'current')

    this.iconEl.setAttribute('src', this.iconActive)
    this.element.classList.remove('finished')
    this.element.classList.add('active', 'current')
  }

  reset() {
    this.element.classList.remove('active', 'current', 'finished')
    this.iconEl.setAttribute('src', this.iconNormal)

    this.content.reset()
  }

  getLayersAfterCurrent() {
    let isAfterCurrentLayer = false

    return this.layerTree.layers.filter((layer) => {
      if (layer === this) {
        isAfterCurrentLayer = true
        return false
      }

      return isAfterCurrentLayer
    })
  }

  resetLayersAfterCurrent() {
    this
      .getLayersAfterCurrent()
      .forEach((layer) => layer.reset())
  }
}

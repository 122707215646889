import { Component } from '../component'

export class InputComponent extends Component {
  constructor(context, inputEl, element, parent) {
    super(context, element, parent)

    inputEl.configuratorInstance = this
    this.inputEl = inputEl
    this.isDirty = false

    this.inputEl.addEventListener('change', () => {
      this.isDirty = true
    })
  }

  reset() {
    this.isDirty = false

    // show element again
    this.element.classList.remove('none')
  }

  getName() {
    return this.inputEl.name
  }

  getValue() {
    return this.inputEl.value
  }
}

import { Component } from '../component'
import { DropdownInputComponent } from '../input/dropdown.input.component'
import { RadioInputComponent } from '../input/radio.input.component'
import { RadioTabsInputComponent } from '../input/radio-tabs.input.component'
import { LayerTabComponent } from '../layer/layer-tab.component'

// every .configurator-section-element has a data-configurator-section-element-type attribute
const sectionElementTypeClassMap = {
  'dropdown': DropdownInputComponent,
  'radio': RadioInputComponent,
  'radio-tabs': RadioTabsInputComponent,
}

export class SectionComponent extends Component {
  init() {
    this.sectionTabEl = this.element.querySelector('.configurator-section__row')
    this.sectionContentEl = this.element.querySelector('.configurator-section__content')
    this.sectionResetButtonEl = this.element.querySelector('.section-reset')

    this.initChildElements()
    this.initTabHandling()
    this.initSectionReset()
  }

  initChildElements() {
    this.elements = []

    const sectionEls = this.element.querySelectorAll('.configurator-section-element')

    for (const sectionEl of sectionEls) {
      const type = sectionEl.dataset.configuratorSectionElementType

      if (!sectionElementTypeClassMap.hasOwnProperty(type)) {
        console.error(`data-configurator-section-element-type ${type} not supported on`, sectionEl)
        continue
      }

      const element = new sectionElementTypeClassMap[type](this.context, sectionEl, this)
      this.elements.push(element)
    }
  }

  initTabHandling() {
    this.sectionTabEl.addEventListener('click', () => {
      this.sectionContentEl.classList.toggle('hide');
      this.sectionTabEl.classList.toggle('hide');
    })
  }

  initSectionReset() {
    if (!this.sectionResetButtonEl) {
      return
    }

    this.sectionResetButtonEl.addEventListener('click', (originalEvent) => {
      originalEvent.stopPropagation()

      this.reset()
      this.resetSiblingSectionsAfterCurrent()
      this.getParentOfType(LayerTabComponent).resetLayersAfterCurrent()

      this.context.dispatchEvent(this, 'sectionReset', {
        originalEvent
      })
    })
  }

  reset() {
    const content = this.getParentLayerContent()
    content.getNextButtonEl().classList.remove('active')

    this.element.setAttribute('data-section-status', 'unchecked')

    this.sectionTabEl.classList.add('deactivate-row')
    this.sectionTabEl.classList.remove('hide')

    // data-sum tab has no sectionContentEl
    this.sectionContentEl?.classList.remove('hide')

    for (const element of this.elements) {
      element.reset()
    }
  }

  hide() {
    this.element.classList.remove('active', 'current')

    this.sectionTabEl.classList.add('deactivate-row')
    this.sectionTabEl.classList.remove('hide')

    // data-sum tab has no sectionContentEl
    this.sectionContentEl?.classList.remove('hide')
  }

  getParentLayerContent() {
    return this.parent
  }

  getSiblingSectionsAfterCurrent() {
    let isAfterCurrentSection = false

    return this.getParentLayerContent().sections.filter((parentSection) => {
      if (parentSection === this) {
        isAfterCurrentSection = true
        return false
      }

      return isAfterCurrentSection
    })
  }

  resetSiblingSectionsAfterCurrent() {
    this
      .getSiblingSectionsAfterCurrent()
      .forEach((siblingSection) => {
        siblingSection.hide()
        siblingSection.reset()
      })
  }
}

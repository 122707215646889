import { InputComponent } from './input.component'

export class RadioInputComponent extends InputComponent {
  constructor(context, element, parent) {
    const inputEl = element.querySelector('input[type="radio"]')
    super(context, inputEl, element, parent)

    this.isDirty = false
    this.initEvents()
  }

  initEvents() {
    this.inputEl.addEventListener('change', (originalEvent) => {
      this.isDirty = true

      this.context.dispatchEvent(this, 'valueChange', {
        originalEvent,
      })
    })
  }

  reset() {
    this.inputEl.checked = false

    // this.isDirty is set to false in super class
    super.reset()
  }
}
